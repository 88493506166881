/* eslint-disable no-undef */
import { useEffect } from "react";

function AliExpressAds() {
  useEffect(() => {
    if (!window.yaContextCb) {
      window.yaContextCb = [];
    }
    // Проверяем, загружен ли уже скрипт Яндекса
    if (!document.querySelector('script[src="https://yandex.ru/ads/system/context.js"]')) {
      const script = document.createElement("script");
      script.src = "https://yandex.ru/ads/system/context.js";
      script.async = true;
      script.onload = () => {
        window.yaContextCb.push(() => {
          Ya.Context.AdvManager.render({
            blockId: "R-A-14540537-1",
            renderTo: "yandex_rtb_R-A-14540537-1",
          });
        });
      };
      document.body.appendChild(script);
    } else {
      // Если скрипт уже загружен, просто рендерим рекламу
      window.yaContextCb.push(() => {
        Ya.Context.AdvManager.render({
          blockId: "R-A-14540537-1",
          renderTo: "yandex_rtb_R-A-14540537-1",
        });
      });
    }
  }, []);

  return (
    <div className="ad-manager">
      <div id="yandex_rtb_R-A-14540537-1"></div>
    </div>
  );
}

export default AliExpressAds;
