import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Search from "./pages/Search";
import Basket from "./pages/Basket";
import PostPage from "./pages/PostPage";
import DocPrices from "./pages/DocPrices";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RestorePassword from "./pages/RestorePassword";
import RedirectProduct from "./pages/RedirectProduct";
import RedirectDownloadMobileApp from "./pages/RedirectDownloadMobileApp";
import devtools from 'devtools-detect';

function App() {
  useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const handleDevToolsChange = () => {
      if (devtools.isOpen && !isIOS ) {
        window.location.href = 'about:blank'; 
      }
    };

    window.addEventListener('devtoolschange', handleDevToolsChange);

    const checkDevToolsOpen = () => {
      const threshold = 160;
      if (window.outerHeight - window.innerHeight > threshold || window.outerWidth - window.innerWidth > threshold) {
        handleDevToolsChange();
      }
    };

    const interval = setInterval(checkDevToolsOpen, 1000);

    return () => {
      window.removeEventListener('devtoolschange', handleDevToolsChange);
      clearInterval(interval);
    };
  }, []);

  return (
    <Router>
      <div>
        <ScrollToTop />
        <TitleUpdater />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post/:postId" element={<PostPage />} />
          <Route path="/search" element={<Search />} />
          <Route path="/basket" element={<Basket />} />
          <Route path="/document/prices" element={<DocPrices />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/restore" element={<RestorePassword />} />
          <Route path="/products" element={<RedirectProduct />} />
          <Route path="/app" element={<RedirectDownloadMobileApp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function TitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    let title = "Texno Finance";
    let description = "Texno Finance - Сеть комисионных магазинов";

    switch (location.pathname) {
      case "/":
        title = "Главная | Texno Finance";
        description = "Texno Finance - Сеть комисионных магазинов";
        break;
      case "/post/:postId":
        title = "Детали Продукта | Texno Finance";
        description = "Просмотр деталей продукта на Texno Finance.";
        break;
      case "/search":
        title = "Поиск | Texno Finance";
        description = "Поиск продуктов на Texno Finance.";
        break;
      case "/basket":
        title = "Корзина | Texno Finance";
        description = "Просмотр вашей корзины на Texno Finance.";
        break;
      case "/document/prices":
        title = "Условия соглашения | Texno Finance";
        description = "Условия соглашения Texno Finance.";
        break;
      default:
        title = "Texno Finance";
        description = "Texno Finance - Сеть комисионных магазинов.";
    }

    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = description;
      document.head.appendChild(meta);
    }
  }, [location.pathname]);

  return null;
}

export default App;
