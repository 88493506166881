import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import app_store_image from "../img/app_store.png";
import play_store_image from "../img/play_store.png";

function Footer() { 
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className="footer-container"
      style={{
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#F5F5F7",
      }}
    >
      {isMobile && (
        <div className="footer-app-links" style={{ marginTop: "20px" }}>
          <img
            onClick={() =>
              handleClick(
                "https://play.google.com/store/apps/details?id=com.beta.texnofinance"
              )
            }
            src={play_store_image}
            alt="Download Play Store"
            style={{ cursor: "pointer" }}
          />
          <img
            onClick={() =>
              handleClick("https://apps.apple.com/us/app/itexno/id6499319456")
            }
            src={app_store_image}
            alt="Download App Store"
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      <div className="footer-contact" style={{ marginTop: "20px" }}>
        <FontAwesomeIcon
          icon={faPhone}
          size="1x"
          style={{ marginRight: "10px" }}
        />
        <span className="footer-phone-number">+7 700 079 75 74</span>
      </div>
      <h5 className="footer-copyright">kz Beta 2025</h5>
    </div>
  );
}

export default Footer;
