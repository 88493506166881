import React, { useState, useEffect } from "react";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import PricePageHello from "../fragments/PricePageHello";
import PricePageSteps from "../fragments/PricePageSteps";
import PricePageUpdated from "./PricePageUpdated";
import SocialLinks from "../fragments/SocialLinks";
import AliExpressAds from "../fragments/AliExpressAds";
import CallNumberInput from "../fragments/CallNumberInput";
import { database } from "../firebase";
import { ref, get } from "firebase/database";

function Home() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [showBottomDiv, setShowBottomDiv] = useState(false);
  const [phoneCallPanelOn, setPhoneCallPanelOn] = useState(false);

  const handleButtonClick = () => {
    setShowBottomDiv(true);
    const element = document.getElementById("bottomFragment");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const togglePhonePanel = () => {
    setPhoneCallPanelOn((prevState) => !prevState); // Toggle phone panel state
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await get(ref(database, "Allows/updateDeposit"));
        if (snapshot.exists()) {
          setData(snapshot.val());
        } else {
          setData("No data available");
        }
      } catch (error) {
        setError("500 Server Error\n\nFailed to retrieve data from server");
        alert("500 Server Error\nFailed to retrieve data from server.");
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar togglePhonePanel={togglePhonePanel} />
      <PricePageHello onButtonClick={handleButtonClick} />
      <PricePageSteps />
      <PricePageUpdated showBottomDiv={showBottomDiv} />

      <AliExpressAds />
      <Footer />
      <SocialLinks />
      <CallNumberInput isOn={phoneCallPanelOn} togglePhonePanel={togglePhonePanel} />
    </div>
  );
}

export default Home;
